export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Spunki",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://game.sprunki.com/game.sprunki.html",
    domain: "spunki.site",
    gaId: "G-KLVD9K7XTV",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
